<template>
<div>
  <ayl-berad-nav :nav="nav"></ayl-berad-nav>
  <div class="clearBox content-box mt10px" v-loading="loading">
    <div class="content-main content-auto" style="padding: 0px 0px 30px 0;">
      <p class="heard-title">
        当前播放: {{ plateNumber }}
      </p>
     <!-- <ayl-video-player></ayl-video-player>  -->
      <div style="center-video">
        <el-row class="videoCol">
          <el-col :span="tabsId !== 5 ? 24 : 12 " :class= "tabsId !== 5 ? 'col-single' : 'col-multiple'" v-for="(item, index) in allVideoUrl" :key="index">
            <ayl-flv :url="item" :class= "tabsId !== 5 ? 'col-single-flv' : 'col-multiple-flv'"></ayl-flv>
          </el-col>
          <!-- <el-col :span="24" style="padding: 8px; background-color: #fff;">
            <div class="video-item" style="margin-right: 10px;">
              <span v-for="(item, idx) in operationList" :key="idx" class="operation-item curp">{{item}}</span>
            </div>
          </el-col> -->
        </el-row>
        <el-row style="margin: 10px">
          <div class="col-main-item" v-for="(item, index) in allAisle" :key="index">
            <div :class="item.id === tabsId ? 'col-item-main' : 'col-item'" @click="onClick(item.id)" v-loading="btnLoading">
              <div class="col-item-test">{{ item.aisle }}</div>
            </div> 
          </div>
          <div class="col-main-item" @click="checkClick([1,2,3,4])">
            <div class="images">
              <img :src= "tabsId === 5 ? require('../../../../assets/images/hw-icon/sigongge2.png') : require('../../../../assets/images/hw-icon/sigongge1.png')">
            </div>
          </div>
        </el-row>
      </div>
    </div>
  </div>
</div>
  
</template>

<script>
  import utils from '@/utils/func'
  export default {
    name: 'BusImg',
    data () {
      return {
        nav: [
           {name: '安全中心'},
           {name: '安全监控', path: '/safety-center/safety-monitoring'},
           {name: '实时视频'},
        ],
        btnIcon: require("../../../../assets/images/hw-icon/sigongge1.png"),
        btnItemIcon: require("../../../../assets/images/hw-icon/sigongge2.png"),
        imageId: 5,
        plateNumber: this.$route.query.plateNumber,
        videoUrl: [],
        carCheckList: [],
        allCheckUrl: [],
        operationList: ['字幕', '云台', '色彩', '比例', '画质', '音量', '设置'],
        // 视频数据
        screenNum: 1,
        allVideoUrl: [],
        allAisle: [{
          aisle: 'CH1',
          camera: 'ADAS摄像头',
          id: 1,
          state: false,
        }, {
          aisle: 'CH2',
          camera: 'DMS摄像头',
          id: 2,
          state: false,
        }, {
          aisle: 'CH3',
          camera: 'BSD摄像头',
          id: 3,
          state: false,
        }, {
          aisle: 'CH4',
          camera: '车内摄像头',
          id: 4,
          state: false,
        },
        // {
        //   aisle: 'CH5',
        //   camera: '360前摄像头',
        //   id: 5,
        //   state: false,
        // }, {
        //   aisle: 'CH6',
        //   camera: '360后摄像头',
        //   id: 6,
        //   state: false,
        // }, {
        //   aisle: 'CH7',
        //   camera: '360左摄像头',
        //   id: 7,
        //   state: false,
        // }, {
        //   aisle: 'CH8',
        //   camera: '360右摄像头',
        //   id: 8,
        //   state: false,
        // },
        ],
        tabsId: 5,
        vidoeSrc: null,
        loading: false,
        btnLoading: false,
        timer: null,
        httpsUrl: 'https://srs.ailivingtech.com:8443'
      }
    },
    methods: {     
      // eslint-disable-next-line no-unused-vars
      async checkClick (value) {
        //获取勾选车辆的所有通道url
        this.tabsId = 5
        this.allVideoUrl = []
        let _allVideoUrl = await this.$api.getRtmpUrls({
          deviceId: this.$route.query.deviceId,
          channelList: value,
          dataType: 0,
          streamType: 0,
          //tenantId: this.carList[0].tenantId
        })

        // for(let key in _allVideoUrl) {       
        //   if(_allVideoUrl[key].slice(0,24) ==='http://47.100.202.0:8080') {
        //     var str = _allVideoUrl[key].slice(24)
        //     var newUrl = this.httpsUrl + str
        //     this.allVideoUrl.push(newUrl)
        //   }else {

        //   }
        // }
        // let httpsUrl = 'https://srs.ailivingtech.com:8443'
        this.$nextTick(() =>{
          // this.allVideoUrl = this.allVideoUrl
          this.allVideoUrl = Object.keys(_allVideoUrl).map((k) => _allVideoUrl[k])
        })
      },

      async onCheckClick (value) {
        //获取勾选车辆的所有通道url
        this.allVideoUrl = []
        let _allVideoUrl = await this.$api.getRtmpUrls({
          deviceId: this.$route.query.deviceId,
          channelList: [value],
          dataType: 0,
          streamType: 0,
          //tenantId: this.carList[0].tenantId
        })
        this.$nextTick(() =>{
          this.allVideoUrl = Object.keys(_allVideoUrl).map((k) => _allVideoUrl[k])
        })  
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.btnLoading= false
        }, 1000);
      },
      
      async onClick(val) {
        this.btnLoading = true
        this.tabsId = val
        this.onCheckClick(val)
      }
    },


    async mounted () {
      this.checkClick([1,2,3,4])
    },
  }
</script>

<style lang='sass' scoped>
  .clearBox
    height: 100%
    overflow: auto

    .heard-title
      padding: 15px 10px 10px 
      font-size: 16px
      color: #2E3033

    .carsVideoLeft
      max-width: 300px
      width: 300px
      background: rgba(255, 255, 255, 1)
      box-shadow: 0px 1px 6px 0px rgba(5, 117, 230, 0.2)
      border-radius: 2px
      // padding: 25px 14px
      margin-right: 10px
      display: inline-block
      vertical-align: top
      overflow: auto

    .carsVideoRight
      width: calc(100% - 310px)
      background: rgba(255, 255, 255, 1)
      box-shadow: 0px 1px 6px 0px rgba(5, 117, 230, 0.2)
      border-radius: 2px
      padding: 25px 14px
      display: inline-block
      vertical-align: top

      .setting
        text-align: right

    .center-btn
      background: rgba(255, 255, 255, 1)
      box-shadow: 0px 1px 6px 0px rgba(5, 117, 230, 0.2)
      border-radius: 2px
      width: 100%

    .center-video
      margin-top: 20px


    .videoCol
      .videoAisle
        padding: 10px 0 20px
        display: inline-block
        vertical-align: middle
        font-size: 12px
        float: right

      .videoLicense
        padding-left: 15px
        display: inline-block
        vertical-align: -webkit-baseline-middle
        font-size: 12px
        line-height: 50px

  /deep/ .el-button--mini.is-round
    padding: 4px 12px

  .video-item
    display: inline-block
    vertical-align: middle
  .active-icon
    color: $-color-primary-2
  .operation-item
    display: inline-block
    width: 42px
    height: 20px
    border-radius: 10px
    font-size: 12px
    text-align: center
    line-height: 20px
    border: 1px solid $grayShallow2
    transition: .35s

    & + .operation-item
      margin-left: 10px

    &:hover
      border-color: $-color-primary-2
      @extend .active-icon

  .col-single
    padding: 10px
    width: 100%
    height: 68vh
    display: block
    background-color: #222222
  .col-multiple
    padding: 10px
    display: block
    background-color: #222222
  .col-single-flv
    width: 75vh
    height: 66vh
    position: relative
    left: 50%
    transform: translateX(-50%)
    display: inline-block
    vertical-align: middle

  .col-multiple-flv
    width: 87vh
    height: 35vh
    position: relative
    left: 50%
    // margin: 0 20px
    transform: translateX(-50%)
    display: inline-block
    vertical-align: middle
  .col-main-item
    width: 114px
    height: 62px
    margin: 0 5px
    display: inline-block
    vertical-align: middle
  .col-item
    border: 1px solid #0575E6
    border-radius: 4px
    // padding: 20px 20px
    width: 114px
    height: 62px
    // margin: 0 5px
    color: #0575E6
    cursor: pointer
  
  .col-item-main
    border: 1px solid #0575E6
    border-radius: 4px
    width: 114px
    height: 62px
    // padding: 20px 20px
    // margin: 0 5px
    color: #FFFFFF
    cursor: pointer
    background: #0575E6

  .col-item-test
    font-size: 16px
    line-height: 62px
    text-align: center

  .images
    width: 114px
    height: 62px
    img
        min-width: 100%
        min-height: 100%
</style>
<style scoped>
  .carsVideoHeight {
    height: calc(80vh)
  }
</style>
